import styled from 'styled-components';

export const BannerWrapper = styled.section`
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;

  .player-wrapper {
    .contents {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      position: relative;

      .btn-play-content {
        width: 100%;
        padding-top: calc(50vh - 60px);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn-play {
        width: 100px;
        height: 100px;
        cursor: pointer;
      }
    }
  }
`;
