import styled from 'styled-components';

export const Content = styled.div`
  margin-inline: 4rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  max-width: calc(50vw - 50px);
  margin-top: 20px;

  @media (max-width: 1100px) {
    max-width: 100%;
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    margin-inline: 2rem;
  }
`;
