import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { BannerWrapper } from './style';
import PlayBtn from 'common/assets/image/syncStage/btn_play.png';
import { openModal } from '@redq/reuse-modal';

function PlayableBanner({ url, idle, children }) {
  const playerRef = useRef(null);

  const handlePlay = () => {
    openModal({
      config: {
        className: 'modal-content',
        disableDragging: true,
        width: '100%',
        height: '100%',
        animationFrom: { transform: 'translateY(100px)' },
        animationTo: { transform: 'translateY(0)' },
        transition: {
          mass: 1,
          tension: 180,
          friction: 26,
        },
      },
      component: () => <ReactPlayer ref={playerRef} url={url} width={'100%'} height={'80vh'} controls playing />,
      closeOnClickOutside: true,
    });
  };

  return (
    <BannerWrapper id="banner_section">
      <div className="player-wrapper">
        <div className="contents">
          {idle}
          <div className="btn-play-content">
            <img alt="play" className="btn-play" src={PlayBtn} onClick={() => handlePlay()} />
          </div>
          {children}
        </div>
      </div>
    </BannerWrapper>
  );
}
export default PlayableBanner;
